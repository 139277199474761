$background-color: #e1f0fd;

.app__contact {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__contact-cards {
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 4rem 2rem 2rem;

  .app__contact-card {
    min-width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    background-color: $background-color;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    img {
      width: 40x;
      height: 40px;
      margin: 0 0.7rem;
    }

    p {
      font-weight: 600;
    }

    a {
      text-decoration: none;
      font-weight: 500;
    }

    &:hover {
      background-size: 100% 100%;
      color: var(--white-color);
      animation: heartbeat 2s infinite;
    }
    
    @-webkit-keyframes heartbeat {
      0%, 100% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      10% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
      20% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      30% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
    
    @keyframes heartbeat {
      0%, 100% {
        transform: scale(1);
      }
      10% {
        transform: scale(1.1);
      }
      20% {
        transform: scale(1);
      }
      30% {
        transform: scale(1.1);
      }
    }
    
    .pulse-grow-on-hover:hover, .pulse-grow-on-hover:focus, .pulse-grow-on-hover:active {
      -webkit-animation-name: heartbeat;
      animation-name: heartbeat;
      -webkit-animation-duration: 1.5s; /* Slower heartbeat */
      animation-duration: 1.5s; /* Slower heartbeat */
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
    }

    @media screen and (max-width: 450px) {
      min-width: 0px;
      width: 100%;

      img {
        margin: 0 0.5rem;
        width: 35px;
        height: 35px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.app__contact-cards .app__contact-card:last-child {
  background-color: $background-color;
}

.app__contact-form {
  width: 60%;
  flex-direction: column;
  margin: 1rem 2rem;

  div {
    width: 100%;
    margin: 0.75rem 0;
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--primary-color);

    transition: all 0.3s ease-in-out;

    input,
    textarea {
      width: 100%;
      padding: 0.95rem;
      border: none;
      border-radius: 7px;
      background-color: var(--primary-color);

      font-family: var(--font-base);
      color: var(--secondary-color) !important;
      outline: none;
    }

    textarea {
      height: 170px;
    }

    &:hover {
      box-shadow: 0 0 25px var(--primary-color);
    }
  }

  // button {
  //   padding: 1rem 2rem;
  //   border-radius: 10px;
  //   border: none;
  //   background-color: var(--secondary-color);

  //   font-weight: 500;
  //   color: var(--white-color);
  //   outline: none;
  //   margin-top: 2rem;
  //   font-family: var(--font-base);
  //   cursor: pointer;
  // }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem 0;
  }
}

#contact-email:hover span {
  display: none
}

#contact-email:hover:before {
  content: "beimnetzish@gmail.com"
}

#contact-call:hover span {
  display: none
}

#contact-call:hover:before {
  content: "+251 9703110489"
}

