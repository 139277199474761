.app__navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem 2rem;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  position: fixed;
  z-index: 2;
  // background-color: rgba($color: #ffffff, $alpha: 0.25);
  // backdrop-filter: blur(4px);
  // -webkit-backdrop-filter: blur(4px);
  // border: 1px solid rgba($color: #ffffff, $alpha: 0.18);
  transition: all 0.3s ease;
}

//.navbar-float {
 // background-color: var(--white-color);
 // box-shadow: 0 0px 25px #424884;
//}

.app__navbar-logo {
  display: flex;
  justify-content: center;
  align-items: center;


  img {
    width: 100px;
    height: calc(100px / 6.4);
    object-fit: contain;
    // object-fit: scale-down;
  }

  @media screen and (min-width: 2000px) {
    width: 180px;
    height: 40px;
  }
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  list-style: none;

  li {
    margin: 0 1rem;
    cursor: pointer;
    // height: 10px;

    a {
      color: #040053;
      // flex-direction: column;
      margin: 5px 0;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 600;
      transition: all 0.3s ease-in-out;

      &::after {
        display: block;
        content: "";
        border-bottom: 1px solid var(--secondary-color);
        transform: scaleX(0);
        transition: transform 0.25s ease-in-out;
        transform-origin: 100% 50%;
      }

      &:hover {
        color: var(--secondary-color);
        font-weight: bold; /* Add this line to make it bold on hover */
        font-size: 1.1em; 
      }
      &:hover:after {
        transform-origin: 0% 50%;
        transform: scaleX(1);
        
      }
    }
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
}

.active {
  a {
    color: var(--secondary-color) !important;
    font-weight: 800;
  }
}

.app__navbar-menu {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);

  svg {
    color: var(--white-color);
    width: 70%;
    height: 70%;
  }

  div {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;

    padding: 1rem;
    width: 80%;
    height: 100vh;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    background: url("../../assets/bgWhite.png");
    background-color: var(--white-color);
    background-size: cover;
    background-repeat: repeat;
    box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);

    svg {
      width: 35px;
      height: 35px;
      color: var(--secondary-color);
      margin: 0.1rem 1rem;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      width: 100%;
      height: 100%;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      li {
        margin: 1rem;

        a {
          color: var(--gray-color);
          text-transform: uppercase;
          font-weight: 500;
          text-decoration: none;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: var(--secondary-color);
          }
        }
      }
    }

    @media screen and (min-width: 900px) {
      display: none;
    }
  }

  @media screen and (min-width: 900px) {
    display: none;
  }
}
