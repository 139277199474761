.app__works {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__work-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 2rem 0 1rem 0;
  //   width: 100%;

  .app__work-filter-item {
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
    background-color: #fff;
    color: black;
    font-weight: 800;
    cursor: pointer;
    margin: 0.5rem;
    transition: all 0.3s ease;

    &:hover {
      background-color: var(--secondary-color);
      color: #fff;
    }

    @media screen and (min-width: 2000px) {
      padding: 1rem 2rem;
      border-radius: 0.85rem;
    }
  }

  .item-active {
    background-color: var(--secondary-color);
    color: #fff;
  }
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
}

.app__work-portfolio {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .app__work-item {
    width: 300px;
    flex-direction: column;
    margin: 2rem;
    padding: 1rem;
    border-radius: 0.3rem;
    background-color: #fcfcfc;
    color: black;
    cursor: pointer;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    

    // transition: all 0.3s ease;

    &:hover {
      background-size: 100% 100%;
      color: var(--white-color);
      animation: heartbeat 2s infinite;
    }
    
    @-webkit-keyframes heartbeat {
      0%, 100% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      10% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
      20% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      30% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
    
    @keyframes heartbeat {
      0%, 100% {
        transform: scale(1);
      }
      10% {
        transform: scale(1.1);
      }
      20% {
        transform: scale(1);
      }
      30% {
        transform: scale(1.1);
      }
    }
    
    .pulse-grow-on-hover:hover, .pulse-grow-on-hover:focus, .pulse-grow-on-hover:active {
      -webkit-animation-name: heartbeat;
      animation-name: heartbeat;
      -webkit-animation-duration: 1.5s; /* Slower heartbeat */
      animation-duration: 1.5s; /* Slower heartbeat */
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
    }
    

    @media screen and (min-width: 2000px) {
      width: 470px;
      padding: 1.25rem;
    }
    @media screen and (max-width: 300px) {
      min-width: 100%;
      margin: 1rem;
    }
  }
  @media screen and (max-width: 300px) {
    width: 100%;
  }

}

.app__work-img {
  width: 100%;
  height: 230px;
  position: relative;

  img {
    border-radius: 0.5rem;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    height: 350px;
  }
}

.app__work-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(38, 59, 105, 0.5);
  border-radius: 0.5rem;

  opacity: 0;
  // transition: all 0.3s ease;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    margin: 1rem;
    font-family: var(--font-base);
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;

    svg {
      width: 50%;
      height: 50%;
      color: var(--white-color);
      transition: all 0.3s ease;
    }
  }

  &:active {
    opacity: 1;
  }
}


.app__work-touched {
  opacity: 1;
}

.app__work-content {
  padding: 0.5rem;
  flex-direction: column;
  width: 100%;
  position: relative;

  h4 {
    margin-top: 1rem;
    line-height: 1.5;
  }
  h4,
  p {
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }

  .app__work-tag {
    position: absolute;
    padding: 0.5rem 1rem;
    background-color: #fcfcfc;
    top: -25px;
    border-radius: 5px;
    width: 50%;
  }
}

.app__work-touched-nowrap {
  .app__work-content {
    h4,
    p {
      white-space: unset;
    }
  }
}

.show-more-button {
  margin: 1rem;
  padding: 0.5rem 1rem;
  margin-top: 3rem;
  background-color:  #fff;
  color: var(--secondary-color);
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--secondary-color);
    color: #FFF;
  }
}

// Below is the CSS for the Show More Button

@mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65, 0, .076, 1)) {
  transition: $property $duration $ease;
}

button {
  position: relative;
  margin-top: 3rem;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  &.show-more {
    width: 10rem; /* Reduced button width */
    height: auto;
    .circle {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
      position: relative;
      display: block;
      margin: 0;
      width: 2rem; /* Reduced circle width */
      height: 2rem; /* Reduced circle height */
      background: var(--secondary-color);
      border-radius: 0.375rem; /* Reduced border-radius */
      .icon {
        @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        background: var(--secondary-color);
        &.arrow {
          @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
          left: 0.4rem; /* Adjusted left position */
          width: 0.9rem; /* Reduced icon width */
          height: 0.1rem; /* Reduced icon height */
          background: none;
          &::before {
            position: absolute;
            content: '';
            top: -0.2rem; /* Adjusted top position */
            right: 0.07rem; /* Adjusted right position */
            width: 0.5rem; /* Reduced arrow width */
            height: 0.5rem; /* Reduced arrow height */
            border-top: 0.12rem solid #fff;
            border-right: 0.12rem solid #fff;
            transform: rotate(45deg);
          }
        }
      }
    }
    .button-text {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0.7rem 0; /* Reduced padding */
      margin: 0 0 0 1.85rem; /* Reduced margin */
      color: var(--secondary-color);
      font-weight: 700;
      line-height: 0.7;
      text-align: center;
      text-transform: uppercase;
    }
  }
  &:hover {
    .circle {
      width: 100%; /* Adjusted width on hover */
      .icon {
        &.arrow {
          background: var(--white-color);
          transform: translate(0.8rem, 0); /* Adjusted translation on hover */
        }
      }
    }
    .button-text {
      color: var(--white-color);
      margin-left: 35px;
    }
  }
}

