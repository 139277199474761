@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&family=Nunito:wght@200;300&display=swap');

:root {
  --font-base: "Nunito", sans-serif;
  --primary-color: #edf2f8;
  --secondary-color: #1b2598;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #5f6671;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

img {
  pointer-events: none;
}
